import mitt, { type EventType } from 'mitt';
import type { BusEventList } from './type';

const emitter = mitt();

class AppEventBus<T> {
  public emit<Event extends keyof T>(eventKey: Event, params?: T[Event]) {
    emitter.emit(eventKey as EventType, params);
  }

  public listen<Event extends keyof T>(eventKey: Event, callback: (params?: T[Event]) => void) {
    emitter.on(eventKey as EventType, e => callback(e as T[Event]));
  };

  public remove<Event extends keyof T>(eventKey: Event) {
    emitter.off(eventKey as EventType);
  }
}

const appEventBus = new AppEventBus<BusEventList>();

export function useEventBus() {
  const { emit, listen, remove } = appEventBus;
  return {
    emit,
    listen,
    remove,
  };
}
